import * as React from "react";
import Layout from "../components/layout";
import Button from "../components/button";
import Section, { Container } from "../components/section";
import ButtonLink from "../components/button-link";
import sizes from "../shared/sizes";
import colors from "../shared/colors";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import Title from "../components/title";

const ImgContainer = styled.div`
  display: inline-block;
  text-align: center;
  flex-grow: 1;
`;

const Accent = styled.span`
  color: ${colors.yellow};
`;

const Hero = styled(Section)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3em;
  ${sizes.phone} {
    display: block;
  }
`;

const Divider = styled.div`
  height: 3em;
`;

const Veil = styled.div`
  width: 100%;
  height: 100%;
  padding: 3em 0;
  background: rgba(0, 0, 0, 0.8);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.7) 10%,
    rgba(0, 0, 0, 0.7) 90%,
    rgba(0, 0, 0, 0.6) 100%
  );
  text-align: center;
`;

const PaddedSpan = styled.span`
  padding: 0 4em;
  font-size: 0.4em;
  ${sizes.phone} {
    padding: 0 0.5em;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 2em;
`;

const ContactSection = styled(Section)`
  padding: 10em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Dot = () => <PaddedSpan>♦</PaddedSpan>;

const IndexPage = ({ data }) => {
  return (
    <main>
      <title>Home Page</title>
      <Layout>
        <Hero dark={false}>
          <Title>
            <h1>Daniel Merrill</h1>
            <h2>Software Engineer</h2>
          </Title>
          <ImgContainer>
            <Img
              fixed={data.profile.childImageSharp.fixed}
              style={{ width: 200, height: 200 }}
            />
          </ImgContainer>
        </Hero>
        <Divider />
        <Section textAlign="left">
          <div>
            <p>
              Hey! My name is <Accent>Daniel Merrill</Accent>. I'm a Software
              Engineer, currently working at{" "}
              <Accent>
                <a href="https://getjusto.com" target="_blank">
                  Justo
                </a>
              </Accent>{" "}
              as a Principal Engineer. I've worked in a range of different
              companies - from my own startup, to 100+ person companies. I enjoy
              being part of organizations with a startup-like culture as the
              fast-paced environment and the ability to make a big impact
              motivates me a lot.
            </p>
          </div>
        </Section>
        <BackgroundImage
          Tag="section"
          fluid={data.desk.childImageSharp.fluid}
          backgroundColor={colors.black}
        >
          <Veil>
            <h3>About me</h3>
            <Container>
              <div>
                <p>
                  I love creating stuff that people use. I started coding when I
                  was a kid and haven't stopped since. I'm product-oriented and,
                  although I do enjoy writting elegant code, I prioritize
                  shipping features, building MVPs, finding product-market fit,
                  and iterating.
                </p>
              </div>
            </Container>
            <Container>
              <Accent>
                <h4>
                  <b>{new Date().getFullYear() - 2001} years</b> of coding.{" "}
                  <b>{new Date().getFullYear() - 2016} years</b> of
                  professional, full-time Software Engineering.
                </h4>
              </Accent>
            </Container>
            <Divider />
            <p>
              More about my career in the <Accent>Career</Accent> section.
            </p>
            <StyledButton
              href="/career"
              color={colors.transparent}
              border={colors.white}
            >
              Go to the Career section
            </StyledButton>
          </Veil>
        </BackgroundImage>
        <ContactSection>
          <p>
            If you have any questions or want to get in touch, feel free to
            contact me using the button below.
          </p>
          <StyledButton
            href="/contact"
            color={colors.transparent}
            border={colors.white}
          >
            Contact Me
          </StyledButton>
        </ContactSection>
      </Layout>
    </main>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    profile: file(relativePath: { eq: "profile-pic-3.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    desk: file(relativePath: { eq: "desk.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
